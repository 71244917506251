import { Component, Inject, LOCALE_ID } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from "@angular/router";
import { StyleClassModule } from "primeng/styleclass";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { SelectButtonChangeEvent, SelectButtonModule } from "primeng/selectbutton";
import { FormsModule } from "@angular/forms";
import {
  selectPlayerSelf,
  selectPlayerSelfIsOnboarded, selectPlayerSelfLoading
} from "../../../features/player-self/store/player-self.selectors";
import { Store } from "@ngrx/store";
import { async, combineLatestWith, map } from "rxjs";
import { AsyncPipe, NgIf } from "@angular/common";
import { DialogService } from "primeng/dynamicdialog";
import { LoginDialogComponent } from "../../../features/auth/components/login-dialog/login-dialog.component";
import { selectAuthIsLoggedIn } from "../../../features/auth/store/auth.selectors";
import { logout } from "../../../features/auth/store/auth.actions";

@Component({
  selector: 'cs-nav-bar',
  standalone: true,
  imports: [
    StyleClassModule,
    ButtonModule,
    RippleModule,
    RouterLink,
    RouterLinkActive,
    SelectButtonModule,
    FormsModule,
    NgIf,
    AsyncPipe
  ],
  providers: [DialogService],
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.scss'
})
export class NavBarComponent {

  playerSelf$ = this.store.select(selectPlayerSelf);

  public mobileNavVisible: boolean = false;

  public toggleMobileNav(): void {
    this.mobileNavVisible = !this.mobileNavVisible;
  }

  showLoginButton$ = this.store.select(selectAuthIsLoggedIn).pipe(
    map(isLoggedIn => {
      return !isLoggedIn;
    }));

  showCompleteOnboardingButton$ = this.store.select(selectPlayerSelfIsOnboarded).pipe(
    combineLatestWith(this.store.select(selectAuthIsLoggedIn), this.store.select(selectPlayerSelfLoading)),
    map(([isOnboarded, isLoggedIn, playerSelfLoading]) => {
      return isLoggedIn && !isOnboarded && !playerSelfLoading;
    }));

  showLoginDialog() {
    this.dialogService.open(LoginDialogComponent, {
      header: $localize `Login`,
      width: '350px',
      baseZIndex: 10000,
      style: { 'max-height': '90vh', 'overflow': 'auto' },
      contentStyle: { 'max-height': '90vh', 'overflow': 'auto' }
    });
  }

  logout() {
    this.store.dispatch(logout());
  }

  constructor(public router: Router, @Inject(LOCALE_ID) public locale: string, private store: Store, private dialogService: DialogService) {
  }
}
