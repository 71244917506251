<div class="surface-overlay">
    <div class="py-5 px-6 flex align-items-center justify-content-between relative lg:static m-auto" style="max-width: 1500px">
        <img src="assets/logo.svg" alt="Image" class="mr-0 lg:mr-5 cursor-pointer h-4rem md:h-5rem" routerLink="/">
        <a pRipple class="cursor-pointer block lg:hidden text-700" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
            <i class="pi pi-bars text-4xl"></i>
        </a>
        <div class="align-items-center flex-grow-1 justify-content-between hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 px-6 lg:px-0 z-4 shadow-2 lg:shadow-none">
            <ul class="list-none p-0 m-0 flex lg:align-items-center text-900 select-none flex-column lg:flex-row cursor-pointer">
                <li>
                    <div pRipple class="flex px-0 lg:px-5 py-3 hover:text-primary-600 font-medium transition-colors transition-duration-150 nav-item" routerLink="/">
                        <span i18n>Players</span>
                    </div>
                </li>
                <li>
                    <div pRipple class="flex px-0 lg:px-5 py-3 hover:text-primary-600 font-medium transition-colors transition-duration-150 nav-item" routerLink="/clubs" routerLinkActive="text-primary">
                        <span i18n>Clubs</span>
                    </div>
                </li>
                <li>
                    <div pRipple class="flex px-0 lg:px-5 py-3 hover:text-primary-600 font-medium transition-colors transition-duration-150 nav-item" routerLink="/leagues" routerLinkActive="text-primary">
                        <span i18n>Leagues</span>
                    </div>
                </li>
                <li>
                    <div pRipple class="flex px-0 lg:px-5 py-3 hover:text-primary-600 font-medium transition-colors transition-duration-150 nav-item" routerLink="/about-us" routerLinkActive="text-primary">
                        <span i18n>About Us</span>
                    </div>
                </li>
            </ul>
            <div class="flex justify-content-between align-items-center lg:block border-top-1 lg:border-top-none surface-border py-3 lg:py-0 mt-3 lg:mt-0">
              <p-button *ngIf="showLoginButton$ | async" pRipple label="Login" i18n-label styleClass="p-button-text font-bold" (onClick)="showLoginDialog()"></p-button>
              <p-button *ngIf="showCompleteOnboardingButton$ | async" pRipple i18n-label label="Complete onboarding" styleClass="p-button-text font-bold" (onClick)="showLoginDialog()"></p-button>
              <div class="relative" *ngIf="(playerSelf$ | async)?.firstName as firstName">
                <a pRipple class="flex px-0 lg:px-5 py-3 align-items-center hover:text-primary font-medium transition-colors transition-duration-150 cursor-pointer"
                   pStyleClass="@next" enterClass="hidden" enterActiveClass="scalein" leaveToClass="hidden" leaveActiveClass="fadeout" [hideOnOutsideClick]="true">
                  <i class="pi pi-user mr-2 font-medium"></i>
                  <span class="font-medium">{{ firstName }}</span>
                  <i class="pi pi-chevron-down ml-3 text-xs md:text-sm"></i>
                </a>
                <ul class="list-none p-0 m-0 border-round shadow-0 lg:shadow-2 lg:absolute surface-overlay hidden origin-top w-full lg:w-15rem">
                  <li class="cursor-pointer" routerLink="/my-bookings">
                    <a pRipple class="flex p-3 align-items-center transition-colors transition-duration-150">
                      <i class="pi pi-list text-sm md:text-xl mr-3"></i>
                      <span i18n>My Bookings</span>
                    </a>
                  </li>
                  <li class="cursor-pointer" (click)="logout()">
                    <a pRipple class="flex p-3 align-items-center text-red-500 hover:text-red-400 transition-colors transition-duration-150">
                      <i class="pi pi-sign-out text-sm md:text-xl mr-3"></i>
                      <span i18n>Logout</span>
                    </a>
                  </li>
                </ul>
              </div>

            </div>
        </div>
    </div>
</div>
